export default {
  de: {
    cookies:
      "Um Ihr Nutzererlebnis zu verbessern verwenden wir Cookies welche die einwandfreie Funktion unserer Website gewährleisten und es uns ermöglichen unseren Datenverkehr zu analysieren.",
    ga_consent:
      "Ich bin damit einverstanden Analysedaten zu senden",
    read_more: "Weitere Informationen",
    accept: "Ich stimme zu",
    not_accept: "Ich stimme nicht zu",
    external_viewer: "externer Viewer",
    link: "Link",
    // side menu
    menu_joerpost: "Joer Post",
    menu_home: "Startseite",
    menu_map: "Karte",
    menu_timeline: "Zeitleiste",
    menu_about: "Über die Ausstellung",
    menu_collection: "Sammlung",
    menu_themes: "Thematische Dossiers",
    menu_terms_of_use: "Nutzungsbedingungen",
    menu_contact_us: "Kontaktieren Sie Uns",
    menu_desc:
      "Die Geschichte des Post- und Telekommunikationsunternehmens des Großherzogtums seit 1842",
    // home page
    home_joerpost: "Joer Post",
    home_title: "175 joer post",
    home_button1: "Entdecken Sie die Zeitleiste",
    home_button2: "Erkunden Sie die Karte",
    home_readmore: "Mehr",
    home_footer:
      "Ein Projekt des Luxembourg Centre for Contemporary and Digital History",
    // map page
    map_legendIntro: "Entdecken Sie die Karte der luxemburgischen Postämter",
    map_officeClose: "Geöffnete Ämter",
    map_officeOpen: "Geschlossene Ämter",
    map_legendDetail: "Ämter",
    map_mapTooltipOpen: "Geöffnete",
    map_mapTooltipClose: "Geschlossene",
    map_mapTooltipFrom: "ab",
    map_mapTooltipTo: "bis",
    map_opacity: "Historische Deckkraft der Karte",
    office: "Postamt",
    central: "Telefonzentrale",
    telegraph: "Telegrafenamt",
    //timeSeries
    "telegraph-1-title": "Telegraph",
    "telegraph-1-desc": "Gesamtlänge der Linien des Netzes (km)",
    "telegraph-2-title": "Telegraph",
    "telegraph-2-desc": "Gesamtzahl der Ämter",
    "telegraph-3-title": "Telegraph",
    "telegraph-3-desc": "Zahl der Ämter im nationalen Dienst",
    "telegraph-4-title": "Telegraph",
    "telegraph-4-desc": "Zahl der Ämter im internationalen Dienst",
    "phone-1-title": "Telefon",
    "phone-1-desc": "Gesamtlänge der Linien (km)",
    "phone-2-title": "Telefon",
    "phone-2-desc": "Gesamtlänge der Luftlinien (km)",
    "phone-3-title": "Telefon",
    "phone-3-desc": "Gesamtlänge der unterirdischen Linien (km)",
    "phone-4-title": "Telefon",
    "phone-4-desc": "Gesamtlänge der Unterwasserlinien (km)",
    "phone-5-title": "Telefon",
    "phone-5-desc": "Gesamtzahl der Stationszentralen",
    "phone-6-title": "Telefon",
    "phone-6-desc": "Gesamtzahl der Abonnenten",
    "phone-7-title": "Telefon",
    "phone-7-desc": "Gesamtzahl der Verbindungen",
    "radio-1-title": "radio",
    "radio-1-desc": "Gesamtzahl der Stationen",
    // timeline page
    timeline_periode: "Zeit",
    timeline_next_event: "nächste Ereignis",
    timeline_prev_event: "vorherige Ereignis",
    milestones_only: "Schlüsseldaten",
    all_events: "Alle Ereignisse",
    // timeline page - legend
    event: "Ereignis",
    infrastructure: "Infrastruktur",
    "legislation and events": "Gesetzgebung und Ereignis",
    legislation: "Gesetzgebung",
    service: "Dienstleistungen",
    cooperation: "Internationale Zusammenarbeit",
    staff: "Personal",
    transport: "Transport",
    building: "Gebäude",
    technology: "Technologie",
    partners: "Partner",
    open: "öffnen",
    related_documents: "verwandte Dokumente",
    items_shown: "Angezeigte Objekte",
    search: "Suche",
    filter_by_year: "Nach Jahr filtern",
    filter_by_type: "Nach Typ filtern",
    uncertain_dates: "Dokumente ohne gesichertes Datum einbeziehen",
    uncertain: "ungesichertes Datum",
    other: "andere",
    book: "buch",
    map: "karte",
    advertising: "werbung",
    video: "video",
    object: "objekt",
    law: "gesetz",
    image: "bild",
    title: "Titel",
    date: "Jahreszahl",
    description: "Beschreibung",
    reset: "zurücksetzen",
    soon_online: "bald online",
    speaker: "Interviewpartner",
    unknown: "unbekannte",
    creator: "Urheber",
    provenance: "Herkunft",
    "no documents found": "keine Dokumente gefunden",
    "reset filters or change query": "Filter zurücksetzen oder Anfrage ändern",
    info: "Informationen",
    help_geo: {
      title: "Die geographische Herangehenweise",
      list: [
        {
          title: "Statistik",
          paragraphs: [
            "8 Parameter betreffend die luxemburgischen (Tele-)kommunikationsnetze.",
            "Daten erhoben durch die Internationale Fernmeldeunion (Union Internationale des Télécommunications, UIT) für den Zeitraum von 1867 bis 1967",
            "Quelle: https://www.itu.int/en/history/Pages/HistoricalStatistics.aspx",
          ],
        },
        {
          title: "Interaktive Karte",
          paragraphs: [
            "Chronologische Entwicklung verschiedener (Tele-)kommunikationsnetze in Luxemburg",
            "Postämter: 1842-2017",
            "Telefonzentralen: Bestand des Netzes. Zwischen 1885 und 1896 (Erste Phase der Einführung des Telefonnetzes). Am 01.01.1963 (Abschluss der Automatisierung des Telefonnetzes). 31.12.1981. 10.08.1992 (Umwandlung der Post- und Telekommunikationsbehörde in das Post- und Telekommunikationsunternehmen",
            "Telegrafenämter: 1862-1895",
            "Die fehlenden Daten werden teilweise mit historischen Karten, die über die virtuelle Karte gelegt werden können, ausgeglichen",
          ],
        },
      ],
      navigation_title: "Navigationsmodi",
      navigation_list: [
        "Detailansicht für jedes Postamt, durch Klicken auf das entsprechende Symbol",
        "Übersicht über die chronologische Entwicklung des Netzes durch Bewegen des Cursers am unteren Ende des Bildschirms",
      ],
    },
    help_time: {
      title: "Die chronologische Herangehensweise",
      list: [
        {
          title: "Dargestellter Zeitraum: 1842-2017",
          paragraphs: [
            "Sieben Teilzeiträume, welche aufgrund wichtiger Entwicklungen im Post- und Telekommunikationssektor definiert wurden",
          ],
        },
        {
          title: "Standarddarstellung: 16 Schlüsselmomente",
          paragraphs: [
            "Möglichkeit 6 weitere Kategorien an Ereignissen oder alle erfassten Ereignisse anzeigen zu lassen, durch Klicken auf die entsprechenden Filter im oberen Bereich des Bildschirms",
          ],
        },
      ],
      navigation_title: "Navigationsmodi",
      navigation_list: [
        "Navigation innerhalb der Chronologie durch Bewegen des Cursors im Zeitstrahl unten auf der Seite",
        "Navigation innerhalb der Ereignisse: Detailansicht jedes Ereignisses mit Archivdokumenten durch Klicken auf selbiges",
        "Übergang von einer Detailansicht zur nächsten, ohne Rückkehr zur globalen Chronologie",
      ],
    },
    ie: {
      paragraphs: [
        "Um Ihren virtuellen Besuch der Ausstellung „175 Joer POST“ zu starten, bitten wir Sie ausschließlich folgende Browser zu benutzen: Firefox, Edge, Chrome oder Safari.",
        "Danke im Voraus für Ihr Verständnis und vor allem… viel Spaß bei der Entdeckungstour!",
        "POST Luxembourg",
      ],
    },
  },
  fr: {
    cookies:
      "Dans le but d’améliorer votre expérience utilisateur sur notre site, nous utilisons des cookies pour nous assurer de son bon fonctionnement et afin d’analyser notre trafic.",
    ga_consent:
      "J'accepte l'envoi de données analytiques",
    read_more: "En savoir plus",
    accept: "J'accepte",
    not_accept: "Je n'accepte pas",
    external_viewer: "viewer externe ",
    link: "lien",
    // side menu
    menu_joerpost: "Joer Post",
    menu_home: "Page d’accueil",
    menu_map: "Carte",
    menu_timeline: "Ligne du temps",
    menu_about: "À propos",
    menu_collection: "Collection",
    menu_themes: "Dossiers thématiques",
    menu_terms_of_use: "Conditions d’utilisation",
    menu_contact_us: "Contactez-Nous",
    menu_desc:
      "Histoire de l’Entreprise des Postes et Télécommunications du Grand-Duché de Luxembourg depuis 1842.",
    // home page
    home_joerpost: "Joer Post",
    home_title: "175 joer post",
    home_button1: "Découvrez la ligne du temps",
    home_button2: "Explorez la carte",
    home_readmore: "Lire plus",
    home_footer:
      "Un projet du Luxembourg Centre for Contemporary and Digital History",
    // map page
    map_legendIntro: "Découvrez la carte des bureaux postaux du Luxemburg",
    map_officeClose: "Bureaux fermés",
    map_officeOpen: "Bureaux ouverts",
    map_legendDetail: "Bureaux",
    map_mapTooltipOpen: "Ouvert",
    map_mapTooltipClose: "Fermé",
    map_mapTooltipFrom: "de",
    map_mapTooltipTo: "à",
    map_opacity: "Opacité de la carte historique",
    office: "Bureau postal",
    central: "Central téléphonique",
    telegraph: "Bureau télégraphique",
    //timeSeries
    "telegraph-1-title": "télégraphe",
    "telegraph-1-desc": "longueur des lignes du réseau entier (km)",
    "telegraph-2-title": "télégraphe",
    "telegraph-2-desc": "totaux du nombre de bureaux",
    "telegraph-3-title": "télégraphe",
    "telegraph-3-desc": "nombre de bureaux à service national",
    "telegraph-4-title": "télégraphe",
    "telegraph-4-desc": "nombre de bureaux à service international",
    "phone-1-title": "téléphone",
    "phone-1-desc": "totaux longueur des lignes (km)",
    "phone-2-title": "téléphone",
    "phone-2-desc": "longueur des lignes aériennes (km)",
    "phone-3-title": "téléphone",
    "phone-3-desc": "longueur des lignes souterraines (km)",
    "phone-4-title": "téléphone",
    "phone-4-desc": "Longueur des lignes sous-marines (km)",
    "phone-5-title": "téléphone",
    "phone-5-desc": "nombre de stations centrales",
    "phone-6-title": "téléphone",
    "phone-6-desc": "nombre de postes d'abonnés",
    "phone-7-title": "téléphone",
    "phone-7-desc": "nombre total de conversations",
    "radio-1-title": "radio",
    "radio-1-desc": "totaux du nombre de stations",
    // timeline page
    timeline_periode: "periode",
    timeline_next_event: "Evénement suivant",
    timeline_prev_event: "Evénement précédent",
    milestones_only: "Dates clé",
    all_events: "Tous les événements",
    // timeline page - legend
    event: "Evénement",
    "legislation and events": "Législation et evénement",
    infrastructure: "Infrastructure",
    service: "Services",
    cooperation: "Coopération internationale",
    staff: "Personnel",
    transport: "Transport",
    building: "Bâtiment",
    technology: "Technologie",
    partners: "Collaborateurs",
    open: "ouvrir",
    related_documents: "documents liés",
    items_shown: "Éléments affichés",
    search: "Recherche",
    filter_by_year: "Filtrer par année",
    filter_by_type: "Filtrer par type",
    uncertain_dates: "inclure documents avec datation incertaine",
    uncertain: "datation incertaine",
    other: "autre",
    book: "livre",
    map: "carte",
    advertising: "publicité",
    video: "vidéo",
    object: "objet",
    law: "loi",
    image: "image",
    title: "titre",
    date: "date",
    description: "description",
    reset: "reset",
    soon_online: "bientôt en ligne",
    speaker: "interviewé",
    unknown: "inconnu",
    creator: "créateur",
    provenance: "provenance",
    "no documents found": "aucun document trouvé",
    "reset filters or change query":
      "remettre les filtres à zéro ou changer la requête",
    info: "infos",
    help_geo: {
      title: "L’approche géographique",
      list: [
        {
          title: "statistique",
          paragraphs: [
            "8 paramètres sur les réseaux de (télé)communications au Luxembourg.",
            "Données recensées par l’Union Internationale des Télécommunications pour la période comprise entre 1867 et 1967.",
            "Source: https://www.itu.int/en/history/Pages/HistoricalStatistics.aspx",
          ],
        },
        {
          title: "carte interactive",
          paragraphs: [
            "Évolution chronologique de différents réseaux de (télé)communications au Luxembourg.",
            "Bureaux postaux : 1842-2017",
            "Centraux téléphoniques : situation du réseau. Entre 1885 et 1896 (Première phase d’introduction du réseau téléphonique). 01.01.1963 (Parachèvement de l’automatisation du réseau téléphonique). 31.12.1981. 10.08.1992 (Transformation de l’Administration des PTT en Entreprise des Postes et Télécommunications)",
            "Bureaux télégraphiques : 1862-1895",
            "Les données lacunaires sont comblées, en partie, par des cartes historiques, pouvant être placées au-dessus de la carte virtuelle",
          ],
        },
      ],
      navigation_title: "Mode de navigation",
      navigation_list: [
        "Vu de détail pour chaque bureau postal en cliquant sur l’icône correspondante.",
        "Aperçu de l’évolution chronologique en déplaçant le curseur en bas de page",
      ],
    },
    help_time: {
      title: "L’approche chronologique",
      list: [
        {
          title: "Période couverte : 1842 – 2017",
          paragraphs: [
            "Sept sous-périodes définies en fonction des développements majeurs dans le domaine des postes et des télécommunications ",
          ],
        },
        {
          title: "Présentation par défaut: 16 événements clé",
          paragraphs: [
            "Possibilité d’activer 6 autres catégories d’événements ou tous les événements répertoriés en utilisant les filtres en haut de page",
          ],
        },
      ],
      navigation_title: "Mode de navigation",
      navigation_list: [
        "Navigation dans la ligne du temps: déplacement du curseur dans la chronologie en bas de page",
        "Navigation dans les événements: vue détaillée de chaque événement avec document(s) d’archives en y cliquant",
        "Passage d’une vue détaillée à l’autre, sans retour à la ligne du temps globale",
      ],
    },
    ie: {
      paragraphs: [
        "Pour accéder à la visite virtuelle de l'exposition \"175 Joer POST\", nous vous invitons à utiliser exclusivement les navigateurs suivants : Firefox, Edge, Chrome ou Safari.",
        "Merci par avance pour votre compréhension et surtout... bonne exploration!",
        "POST Luxembourg",
      ],
    },
  },
};
